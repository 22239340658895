"use client";

import { useEffect } from "react";

const Error = ({ error }: any) => {
  useEffect(() => {
    if ((window as any).newrelic) {
      (window as any).newrelic.noticeError(error);
    }
  }, [error]);

  return <div>Something went wrong</div>;
};

export default Error;
